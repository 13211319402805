import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import TypeAnimation from 'react-type-animation';

const ContentPage = () => (
    <Layout>
        <SEO title="Webmaster + Marketing IT Support"/>
        <section className="page-heading">
            <div className="container">
                <TypeAnimation
                    cursor={false}
                    sequence={['Webmaster +', 1000, 'Webmaster + Marketing', 1000, 'Webmaster + Marketing IT Support', 1000,]}
                    className={'title'}
                    wrapper="h1"
                />
            </div>
        </section>
        <section className="web-it-support">
            <div className="container">
                <Products>
                    <div title="Monthly Retainer" subtitle="$1,125" id="support-monthly">
                        <p>Want someone to take care of setting up new email accounts? Figuring out your hosting? Changing/updating your DNS? Making sure your website security, files, plugins are all up to date? Connecting your analytics accounts, pixels, URL redirects and watching over your webmaster dashboard to catch errors and glitches with google search results?</p>
                        <p>We take the stress and anxiety out of the equation and make it as simple as chatting or sending us a task/ticket to handle for you.</p>
                        <p>Includes up to 10 hours of support for any and all email, website, hosting, CRM, social technical issues and glitches that can be handled remotely + 5 hours of proactive website management to keep your business engine humming smoothly and prevent as many minor + major issues from arising as possible.</p>
                        <p>Hours do not roll-over, but over the course of the year, we know that all hours will be used up.</p>
                    </div>
                    <div title="Quarterly Retainer" subtitle="$4,500 per quarter" id="support-quarterly">
                        Includes up to 15 hours of support per month, for any and all email, website, hosting, CRM and social technical issues and glitches that can be handled remotely. As well as 5 hours per month of proactive website management, update requests and changes to keep your business engine looking fresh and humming smoothly to prevent as many minor + major issues from arising as possible.
                    </div>
                    <div title="Emergency Hourly Support" subtitle="$250 per hour" id="support-emergency">
                        Minimum of a 2 hour booking to assess and provide (hopefully) a solution or an outline of the work needed, which may lead to additional hours needing to be purchased.
                    </div>
                    <div title="Additional Hourly Support for your retainer" subtitle="$125 per hour" id="support-additional">
                        Purchased in 3 hour blocks and roll-over if they are unused and are always used last within a month.
                    </div>
                </Products>
            </div>
        </section>
    </Layout>
)

export default ContentPage;
